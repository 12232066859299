var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "商品补充",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "refFormData",
          staticClass: "contents",
          attrs: {
            "label-width": "170px",
            model: _vm.formData,
            rules: _vm.formRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原城市仓", prop: "logistics_id" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.originLogisticsName,
                  callback: function ($$v) {
                    _vm.originLogisticsName = $$v
                  },
                  expression: "originLogisticsName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "迁移到", prop: "after_logistics_id" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "选择城市仓",
                      },
                      model: {
                        value: _vm.formData.after_logistics_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "after_logistics_id", $$v)
                        },
                        expression: "formData.after_logistics_id",
                      },
                    },
                    _vm._l(_vm.cityStoreList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name,
                          disabled: item.id === _vm.formData.logistics_id,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "迁移原因", prop: "migrate_reason" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: "输入备注",
                  maxlength: "200",
                  "show-word-limit": "",
                  clearable: "",
                },
                model: {
                  value: _vm.formData.migrate_reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "migrate_reason", $$v)
                  },
                  expression: "formData.migrate_reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }