var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-wrap" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "运费结算变更记录",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "75%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onHandleClose,
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onHandleClose },
                    },
                    [_vm._v("返回")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              staticClass: "row-box d-flex flex-column",
              staticStyle: { height: "calc(100% - 45px)" },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    height: "100%",
                    border: true,
                    "header-cell-style": {
                      color: "#333333",
                      background: "#EFF6FF",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "100px",
                      align: "center",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "content",
                      align: "center",
                      label: "变更记录",
                      "min-width": "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "business_user",
                      align: "center",
                      label: "操作人",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.business_user &&
                                    scope.row.business_user.nickname) ||
                                    "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      align: "center",
                      label: "操作时间",
                      "min-width": "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-position" },
                [
                  _c("el-pagination", {
                    attrs: {
                      type: "primary",
                      background: "",
                      "current-page": _vm.formData.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.formData.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.onHandleSizeChange,
                      "current-change": _vm.onHandleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }