<template>
  <div class="page-wrap">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="refresh"
        @click="onHandleRefresh"
      ></el-button>
      <el-button type="primary" @click="onHandleAdd">新增</el-button>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="formData.logisticBusinessId"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.name"
            placeholder="请输入城市仓名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择状态"
          >
            <el-option
              v-for="item of DISABLED_LIST"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 增加提现状态 start -->
        <div class="inputs">
          <el-select
            v-model="formData.is_withdrawal"
            clearable
            placeholder="选择提现状态"
          >
            <el-option
              v-for="item of withdrawalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 增加提现状态 end -->
        <el-button @click="onSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <!-- 总控增加导出功能 start -->
        <el-button
          @click="onHandleExport"
          :disabled="!tableData.length || disabledExport"
          type="warning"
          icon="el-icon-download"
          >导 出</el-button
        >
        <!-- 总控增加导出功能 end -->
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="id" width="100px" align="center" label="ID">
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="城市仓名称"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.name || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="集配业务中心"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.business && scope.row.business.name) || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="delivery_number"
          align="center"
          label="自提点数量"
        >
          <template slot-scope="scope">
            {{ scope.row.delivery_number }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.status === DISABLED_ENUM.unDisabled.value"
              >启用</el-tag
            >
            <el-tag
              type="warning"
              v-if="scope.row.status === DISABLED_ENUM.disabled.value"
              >禁用</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.status === DISABLED_ENUM.closed.value"
              >已关仓</el-tag
            >
          </template>
        </el-table-column>

        <!-- 增加提现状态 start -->
        <el-table-column prop="is_withdrawal" align="center" label="提现状态">
          <template slot-scope="scope">
            <span
              class="text-btn"
              v-if="scope.row.status !== DISABLED_ENUM.closed.value"
              @click="onHandleWithdrawal(scope.row)"
              >{{
                withdrawalList.find(
                  (item) =>
                    item.value ===
                    (scope.row.is_withdrawal || IS_WITHDRAWAL.no.value)
                ).label
              }}</span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- 增加提现状态 end -->

        <!-- 增加创建时间 start -->
        <el-table-column prop="create_time" align="center" label="创建时间">
        </el-table-column>
        <!-- 增加创建时间 end -->

        <!-- 列表操作栏 start -->
        <el-table-column label="操作" align="center" min-width="150">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                v-if="
                  scope.row.upgrade_position ===
                    UPGRADE_POSITION_ENUM.common.value &&
                  scope.row.status !== DISABLED_ENUM.closed.value
                "
                @click="onHandleUpStorage(scope.row)"
                >升仓</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                @click="onHandleEditData(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="onHandleDisabled(scope.row)"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                >{{
                  scope.row.status === DISABLED_ENUM.unDisabled.value
                    ? "禁用"
                    : "启用"
                }}</el-button
              >
              <el-button
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                type="text"
                @click="onHandleClose(scope.row)"
                >关仓</el-button
              >
            </div>
            <div>
              <el-button type="text" @click="onHandleDeliverDetail(scope.row)"
                >查看自提点</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                @click="onHandleAddDeliver(scope.row)"
                >新增自提点</el-button
              >
            </div>
            <!-- 只有admin权限才展示 start -->
            <div>
              <el-button
                v-if="
                  isAdmin && scope.row.status !== DISABLED_ENUM.closed.value
                "
                type="text"
                @click="onHandleProduct(scope.row)"
                >商品补充</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                @click="onHandleMove(scope.row)"
                >迁移用户</el-button
              >
              <!-- <el-button
                type="text"
                :disabled="disabledExportRow"
                @click="onHandleExportRow(scope.row)"
                >对账下载</el-button
              > -->
            </div>
            <!-- 只有admin权限才展示 end -->
          </template>
        </el-table-column>
        <!-- 列表操作栏 end -->
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 升仓 start -->
    <upgrade-store-dialog
      ref="refUpgradeStore"
      :bankList="bankList"
      @on-show-image="onHandleChangeData"
      @on-refresh-list="ajaxGetTableList"
    ></upgrade-store-dialog>
    <!-- 升仓 end -->

    <!-- 新增/修改城市仓 start -->
    <edit-store-dialog
      ref="refEditStore"
      :logisticsList="logisticsList"
      :bankList="bankList"
      @on-show-image="onHandleChangeData"
      @on-refresh-list="ajaxGetTableList"
    ></edit-store-dialog>
    <!-- 新增/修改城市仓 end -->

    <!-- 新增/编辑自提点 start -->
    <edit-delivery-dialog
      ref="refEditDelivery"
      :bankList="bankList"
      @on-add-delivery="onHandleAddDelivery"
      @on-edit-delivery="onHandleDeliverDetail"
    ></edit-delivery-dialog>
    <!-- 新增/编辑自提点 end -->

    <!-- 查看自提点 start -->
    <delivery-list-dialog
      ref="refDeliveryList"
      @on-edit-delivery="onHandleEditDelivery"
      @on-edit-log="onHandleEditLog"
    ></delivery-list-dialog>
    <!-- 查看自提点 end -->

    <!-- 商品补充 start -->
    <product-supplement-dialog
      ref="refProductSupplement"
      @on-refresh-list="ajaxGetTableList"
    ></product-supplement-dialog>
    <!-- 商品补充 end -->

    <!-- 自提点变更记录 start -->
    <delivery-edit-log-dialog
      ref="refDeliveryEditLog"
    ></delivery-edit-log-dialog>
    <!-- 自提点变更记录 end -->

    <!-- 上传图片预览 start -->
    <el-dialog :visible.sync="showImage">
      <img :src="bigImage" alt="" class="showImg" />
    </el-dialog>
    <!-- 上传图片预览 end -->

    <!-- 迁移用户 start -->
    <move-user-dialog
      ref="refMoveUserDialog"
      @on-refresh-list="ajaxGetTableList"
    ></move-user-dialog>
    <!-- 迁移用户 end -->
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import {
  UPGRADE_POSITION_ENUM,
  DISABLED_ENUM,
  DISABLED_LIST,
  IS_WITHDRAWAL,
  SHOW_ADDTDC_BUTTON_STATUS,
} from "./utils/enum/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
import UpgradeStoreDialog from "./modules/upgrade-store-dialog/index";
import EditStoreDialog from "./modules/edit-store-dialog/index";
import EditDeliveryDialog from "./modules/edit-delivery-dialog/index";
import DeliveryListDialog from "./modules/delivery-list-dialog/index";
import ProductSupplementDialog from "./modules/product-supplement-dialog/index";
import DeliveryEditLogDialog from "./modules/delivery-edit-log-dialog/index";
import MoveUserDialog from "./modules/move-user-dialog/index";
import { postLogisticsListExport } from "@/api/export/center";
import {
  postCityStoreWithdrawalStatus,
  postCityStoreClose,
} from "@/api/general/city-store";
import { postExportLogisticsWallet } from "@/api/export/index.js";

export default {
  name: "CityStoreManage",
  components: {
    UpgradeStoreDialog,
    EditStoreDialog,
    EditDeliveryDialog,
    DeliveryListDialog,
    ProductSupplementDialog,
    DeliveryEditLogDialog,
    MoveUserDialog,
  },
  data() {
    return {
      loading: false,
      disabledExport: false, // 导出禁用
      disabledExportRow: false,
      isAdmin: false, // 是否为admin权限
      UPGRADE_POSITION_ENUM,
      DISABLED_ENUM,
      DISABLED_LIST,
      IS_WITHDRAWAL,
      SHOW_ADDTDC_BUTTON_STATUS,
      upgrade_position: UPGRADE_POSITION_ENUM.common.value, // 是否升仓
      withdrawalList: Object.values(IS_WITHDRAWAL), // 提现状态
      formData: {
        name: "", // 城市仓名称
        status: DISABLED_LIST[2].value, // 禁用状态
        logisticBusinessId: "", // 集配中心id
        is_withdrawal: "", // 提现状态
        page: 1,
        pageSize: 10,
      }, // 表单参数
      formDataClone: {}, // 表单参数拷贝，用于重置
      tableData: [], // 城市仓列表
      total: 0,
      bigImage: "", // 图片地址
      showImage: false, // 是否展示图片
      logisticsList: [], // 集配中心列表
      bankList: [], // 银行列表
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.getAjaxLogisticsList();
      this.ajaxGetTableList();
      this.ajaxGetBankList();
    },
    /**
     * 将子组件值传给父组件
     */
    onHandleChangeData(data) {
      Object.keys(data).forEach((_key) => {
        this[_key] = data[_key];
      });
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取银行列表
     */
    async ajaxGetBankList() {
      try {
        const { data } = await this.$api.general.banksList();
        this.bankList = data;
      } catch (err) {
        console.log("ajax banksList err", err);
      }
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableList();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.ajaxGetTableList();
    },
    /**
     * 获得城市仓列表
     */
    async ajaxGetTableList() {
      this.loading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        const { total, show_addtdc_button, data: list } = data;
        this.tableData = list;
        this.total = total;
        this.isAdmin =
          show_addtdc_button == SHOW_ADDTDC_BUTTON_STATUS.show.value
            ? true
            : false;
      } catch (err) {
        console.log("ajax logisticsList err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetTableList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableList();
    },
    /**
     * 城市仓启用/禁用，二次确认
     */
    onHandleDisabled(row) {
      const msg =
        row.status === DISABLED_ENUM.unDisabled.value
          ? "确定要禁用吗？"
          : "确定要启用吗？";
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostLogisticsStatus(row);
      });
    },
    /**
     * 城市仓启用/禁用，接口请求
     */
    async ajaxPostLogisticsStatus(row) {
      const status =
        row.status === DISABLED_ENUM.unDisabled.value
          ? DISABLED_ENUM.disabled.value
          : DISABLED_ENUM.unDisabled.value;
      const params = {
        logistics_id: row.id,
        status,
      };
      try {
        await this.$api.general.logisticsStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === row.id);
        this.tableData[rowIndex].status = status;
        const msg =
          row.status === DISABLED_ENUM.disabled.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax logisticsStatus err", err);
      }
    },
    /**
     * 升仓操作
     */
    onHandleUpStorage(data) {
      const { name, id } = data;
      const params = {
        name,
        id,
      };
      this.$refs.refUpgradeStore.onInitData(params);
    },
    /**
     * 新增城市仓操作
     */
    onHandleAdd() {
      this.$refs.refEditStore.onInitData();
    },
    /**
     * 编辑城市仓
     */
    onHandleEditData(row) {
      this.$refs.refEditStore.onInitData(row);
    },
    /**
     * 新增自提点
     */
    onHandleAddDeliver(row) {
      const params = {
        module: "add",
        ...row,
      };
      this.$refs.refEditDelivery.onInitData(params);
    },
    /**
     * 编辑自提点
     */
    onHandleEditDelivery(data, row) {
      const params = {
        module: "edit",
        ...data,
      };
      this.$refs.refEditDelivery.onInitData(params, row);
    },
    /**
     * 查看自提点变更记录
     */
    onHandleEditLog(data, row) {
      const params = {
        ...data,
      };
      this.$refs.refDeliveryEditLog.onInitData(params, row);
    },
    /**
     * 新增自提点，成功后更新数量，减少一次接口
     */
    onHandleAddDelivery(data) {
      const index = this.tableData.findIndex((item) => item.id === data);
      this.tableData[index].delivery_number++;
    },
    /**
     * 查看自提点
     */
    onHandleDeliverDetail(row) {
      // console.log("🆒 onHandleDeliverDetail", row);
      if (!row.delivery_number) {
        this.$message.warning("该城市仓下暂无自提点~");
        return;
      }
      this.$refs.refDeliveryList.onInitData(row);
    },
    /**
     * 迁移用户
     */
    onHandleMove(row) {
      this.$refs.refMoveUserDialog.onInitData(row);
    },
    /**
     * 关仓，二次确认
     */
    onHandleClose(row) {
      this.$confirm("关仓前需迁移用户，确定要关仓吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostCityStoreClose(row);
      });
    },
    /**
     * 关仓
     */
    async ajaxPostCityStoreClose(row) {
      const params = {
        logistics_id: row.id,
      };
      try {
        await postCityStoreClose(params);
        this.$message.success("操作成功");
        this.ajaxGetTableList();
      } catch (err) {
        console.log("ajax postCityStoreClose err", err);
      }
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 新导出逻辑 */
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        delete query.page;
        delete query.pageSize;
        await postLogisticsListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postLogisticsListExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
      // this.$confirm("导出任务已提交，请前往【下载中心】查看进度并下载", "提示", {
      //   center: true,
      //   confirmButtonText: "前往【下载中心】",
      //   cancelButtonText: "关 闭",
      //   customClass: "jn-confirm-message-style",
      //   iconClass: "el-icon-warning",
      // })
      //   .then(() => {
      //     this.$router.push({
      //       path: "/export-center",
      //     });
      //   })
      //   .catch();
    },
    /**
     * 对账下载
     */
    async onHandleExportRow(row) {
      this.disabledExportRow = true;

      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 999,
      });
      try {
        const params = {
          logistics_id: row.id,
        };
        const res = await postExportLogisticsWallet(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax postExportLogisticsWallet", err);
      } finally {
        this.$message.closeAll();
        this.disabledExportRow = false;
      }
    },
    /**
     * 操作提现状态
     */
    onHandleWithdrawal(row) {
      const { name, is_withdrawal } = row;
      const hint = is_withdrawal === IS_WITHDRAWAL.yes.value ? "禁止" : "允许";
      const msg = `您将${hint}城市仓【${name}】提现，是否确认？`;
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 认",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      })
        .then(() => this.ajaxPostCityStoreWithdrawal(row))
        .catch();
    },
    /**
     * 城市仓提现状态
     */
    async ajaxPostCityStoreWithdrawal(row) {
      const { id, is_withdrawal } = row;
      const withdrawal =
        is_withdrawal === IS_WITHDRAWAL.yes.value
          ? IS_WITHDRAWAL.no.value
          : IS_WITHDRAWAL.yes.value;
      const params = {
        id,
        is_withdrawal: withdrawal,
      };
      try {
        await postCityStoreWithdrawalStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === id);
        this.tableData[rowIndex].is_withdrawal = withdrawal;
        const msg =
          is_withdrawal === IS_WITHDRAWAL.yes.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax postCityStoreWithdrawalStatus err", err);
      }
    },
    /**
     * 商品补充
     */
    onHandleProduct(row) {
      const { name, id } = row;
      const params = {
        name,
        id,
      };
      this.$refs.refProductSupplement.onInitData(params);
    },
  },
};
</script>
<style lang="scss">
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        margin: 10px;
        padding: 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
