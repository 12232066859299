<template>
  <div class="modules-wrap">
    <el-dialog
      title="查看自提点"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClose"
      width="75%"
      height="85%"
      center
    >
      <div class="search-wrap">
        <div class="form-item">
          <span class="label">状态：</span>
          <div class="value">
            <el-select
              v-model="formData.status"
              @change="onChangeStatus"
              clearable
              placeholder="选择状态"
            >
              <el-option
                v-for="item of DISABLED_LIST"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <span class="label">自提点：</span>
          <div class="value">
            <el-input
              @input="onChange"
              v-model="formData.delivery_name"
              placeholder="自提点名称"
              clearable
            ></el-input>
          </div>
        </div>
      </div>
      <div class="content" v-loading="dialogLoading">
        <el-table
          :data="tableData"
          height="100%"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        >
          <el-table-column
            type="index"
            width="100px"
            align="center"
            label="序号"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="自提点名称"
            min-width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.name || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="自提点地址"
            min-width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.address || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="mobile" align="center" label="联系电话">
            <template slot-scope="scope">
              {{ scope.row.mobile || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="freight_parameter"
            align="center"
            label="运费标准(元)"
          >
            <template slot-scope="scope">
              {{ scope.row.freight_parameter }}
            </template>
          </el-table-column>
          <el-table-column prop="status" align="center" label="状态">
            <template slot-scope="scope">
              {{
                Object.values(DISABLED_ENUM).find(
                  (child) => child.value === scope.row.status
                ).label
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                @click="onHandleEditData(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="onHandleDisabled(scope.row)"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                >{{
                  scope.row.status === DISABLED_ENUM.unDisabled.value
                    ? "禁用"
                    : "启用"
                }}</el-button
              >
              <el-button type="text" @click="onHandleEditLog(scope.row)"
                >变更记录</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status !== DISABLED_ENUM.closed.value"
                @click="onHandleDeliveryClose(scope.row)"
                >永久关闭</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-wrap">
          <el-pagination
            type="primary"
            background
            @size-change="onHandleSizeChange"
            @current-change="onHandleCurrentChange"
            :current-page="formData.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button type="primary" @click="onHandleClose">返回</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { postCityStoreDeliveryClose } from "@/api/general/city-store";
import { DISABLED_ENUM, DISABLED_LIST } from "../../utils/enum/index";

export default {
  name: "DeliveryListDialog",
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      DISABLED_ENUM,
      DISABLED_LIST,
      /** 查询参数 */
      formData: {
        logistics_id: "",
        status: "", // 自提点状态
        delivery_name: "", // 新增搜索自提点参数
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      tableData: [],
      total: 0,
      tableRow: {}, // 当前行数据
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.formData.logistics_id = data.id;
      this.tableRow = data;
      this.ajaxGetDeliveryList();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.formData = {
        ...this.formDataClone,
      };
    },
    /**
     * 获取自提点列表
     */
    async ajaxGetDeliveryList() {
      this.dialogLoading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.log("ajax ajaxGetDeliveryList err", err);
      } finally {
        this.dialogLoading = false;
      }
    },
    /**
     * 搜索自提点
     */
    onChange: debounce(function () {
      this.formData.page = 1;
      this.ajaxGetDeliveryList();
    }, 500),
    /**
     * 修改了状态
     */
    onChangeStatus() {
      this.formData.page = 1;
      this.ajaxGetDeliveryList();
    },
    /**
     * 自提点永久关闭，二次确认
     */
    onHandleDeliveryClose(row) {
      this.$confirm("确定要永久关闭该自提点吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostCityStoreDeliveryClose(row);
      });
    },
    /**
     * 自提点永久关闭
     */
    async ajaxPostCityStoreDeliveryClose(row) {
      const params = {
        delivery_id: row.id,
      };
      try {
        await postCityStoreDeliveryClose(params);
        this.$message.success("操作成功");
        this.onChangeStatus();
      } catch (err) {
        console.log("ajax postCityStoreDeliveryClose err", err);
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetDeliveryList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetDeliveryList();
    },
    /**
     * 自提点启用/禁用，二次确认
     */
    onHandleDisabled(row) {
      const msg =
        row.status === DISABLED_ENUM.unDisabled.value
          ? "确定要禁用吗？"
          : "确定要启用吗？";
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostDeliveryStatus(row);
      });
    },
    /**
     * 自提点启用/禁用，接口请求
     */
    async ajaxPostDeliveryStatus(row) {
      const status =
        row.status === DISABLED_ENUM.unDisabled.value
          ? DISABLED_ENUM.disabled.value
          : DISABLED_ENUM.unDisabled.value;
      const params = {
        delivery_id: row.id,
        status,
      };
      try {
        await this.$api.general.deliveryStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === row.id);
        this.tableData[rowIndex].status = status;
        const msg =
          row.status === DISABLED_ENUM.disabled.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax ajaxPostDeliveryStatus err", err);
      }
    },
    /**
     * 编辑自提点
     */
    onHandleEditData(row) {
      this.$emit("on-edit-delivery", row, this.tableRow);
    },
    /**
     * 变更记录
     */
    onHandleEditLog(row) {
      this.$emit("on-edit-log", row, this.tableRow);
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  .content {
    height: 500px;
    border: 1px solid #ebeef5;
  }
}
.search-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 10px;
  .form-item {
    display: flex;
    align-items: center;
    height: 40px;
    .value {
      width: 207px;
    }
  }
}
.pagination-wrap {
  box-sizing: border-box;
  padding: 10px 0;
}
</style>
