var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onHandleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  ref: "refDeliveryForm",
                  attrs: {
                    model: _vm.deliveryForm,
                    rules: _vm.deliveryRules,
                    "label-width": "180px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "城市仓", prop: "logistics_id" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入城市仓",
                              clearable: "",
                            },
                            model: {
                              value: _vm.cityStoreName,
                              callback: function ($$v) {
                                _vm.cityStoreName = $$v
                              },
                              expression: "cityStoreName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "自提点名称", prop: "name" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入自提点名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.deliveryForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.deliveryForm, "name", $$v)
                              },
                              expression: "deliveryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "自提点地址", prop: "address" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入自提点地址",
                              clearable: "",
                            },
                            model: {
                              value: _vm.deliveryForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.deliveryForm, "address", $$v)
                              },
                              expression: "deliveryForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号昵称", prop: "nickname" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入账号昵称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.deliveryForm.nickname,
                              callback: function ($$v) {
                                _vm.$set(_vm.deliveryForm, "nickname", $$v)
                              },
                              expression: "deliveryForm.nickname",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "account_number" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                autocomplete: "off",
                                disabled: _vm.isEdit,
                                placeholder: "请输入登录账号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.deliveryForm.account_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryForm,
                                    "account_number",
                                    $$v
                                  )
                                },
                                expression: "deliveryForm.account_number",
                              },
                            },
                            [
                              _vm.dialogTitle == "编辑自提点"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "suffix",
                                        type: "text",
                                        disabled: !_vm.businessUserId,
                                      },
                                      on: { click: _vm.onHandleResetPassword },
                                      slot: "suffix",
                                    },
                                    [_vm._v("重置密码")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "密码", prop: "password" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入密码",
                                  clearable: "",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.deliveryForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.deliveryForm, "password", $$v)
                                  },
                                  expression: "deliveryForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "mobile" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入联系电话",
                              maxlength: "11",
                              clearable: "",
                            },
                            model: {
                              value: _vm.deliveryForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.deliveryForm, "mobile", $$v)
                              },
                              expression: "deliveryForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "运费标准", prop: "freight_parameter" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入运费标准",
                                clearable: "",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.deliveryForm.freight_parameter =
                                    _vm.tool.changeValue(
                                      _vm.deliveryForm.freight_parameter
                                    )
                                },
                              },
                              model: {
                                value: _vm.deliveryForm.freight_parameter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryForm,
                                    "freight_parameter",
                                    $$v
                                  )
                                },
                                expression: "deliveryForm.freight_parameter",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#333333" },
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("元")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "运费分成", prop: "freight_share" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入运费分成",
                                clearable: "",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.deliveryForm.freight_share =
                                    _vm.tool.changeValue(
                                      _vm.deliveryForm.freight_share
                                    )
                                },
                              },
                              model: {
                                value: _vm.deliveryForm.freight_share,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryForm,
                                    "freight_share",
                                    $$v
                                  )
                                },
                                expression: "deliveryForm.freight_share",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#333333" },
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("%")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自采品服务费(斤)",
                        prop: "self_purchase_parameter",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入自采品服务费（斤）",
                                clearable: "",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.deliveryForm.self_purchase_parameter =
                                    _vm.tool.changeValue(
                                      _vm.deliveryForm.self_purchase_parameter
                                    )
                                },
                              },
                              model: {
                                value: _vm.deliveryForm.self_purchase_parameter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryForm,
                                    "self_purchase_parameter",
                                    $$v
                                  )
                                },
                                expression:
                                  "deliveryForm.self_purchase_parameter",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#333333" },
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("元")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "parameter_tip" }, [
                    _vm._v("线下自采登记的服务费，非地采"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务费标准", prop: "foundation_prop" } },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "d-flex a-center" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v("商品单价基点：")]
                            ),
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入商品单价基点",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.deliveryForm.purchase_parameter
                                      .foundation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.deliveryForm.purchase_parameter,
                                      "foundation",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "deliveryForm.purchase_parameter.foundation",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#333333" },
                                    attrs: { slot: "suffix" },
                                    slot: "suffix",
                                  },
                                  [_vm._v("元")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex a-center mt-10" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "选择条件",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSymbol(
                                      "foundationGreater"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.foundationGreater.symbol,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.foundationGreater,
                                      "symbol",
                                      $$v
                                    )
                                  },
                                  expression: "foundationGreater.symbol",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "大于等于", value: ">=" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "大于", value: ">" },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "base-margin" }, [
                              _vm._v("基点"),
                            ]),
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入金额",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.foundationGreater.amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.foundationGreater,
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "foundationGreater.amount",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#333333" },
                                    attrs: { slot: "suffix" },
                                    slot: "suffix",
                                  },
                                  [_vm._v("元/件")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex a-center mt-10" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "选择条件",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSymbol("foundationLess")
                                  },
                                },
                                model: {
                                  value: _vm.foundationLess.symbol,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.foundationLess, "symbol", $$v)
                                  },
                                  expression: "foundationLess.symbol",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "小于等于", value: "<=" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "小于", value: "<" },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "base-margin" }, [
                              _vm._v("基点"),
                            ]),
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入金额",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.foundationLess.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.foundationLess, "amount", $$v)
                                  },
                                  expression: "foundationLess.amount",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#333333" },
                                    attrs: { slot: "suffix" },
                                    slot: "suffix",
                                  },
                                  [_vm._v("元/件")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务费分成", prop: "purchase_share" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入服务费分成",
                                clearable: "",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.deliveryForm.purchase_share =
                                    _vm.tool.changeValue(
                                      _vm.deliveryForm.purchase_share
                                    )
                                },
                              },
                              model: {
                                value: _vm.deliveryForm.purchase_share,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryForm,
                                    "purchase_share",
                                    $$v
                                  )
                                },
                                expression: "deliveryForm.purchase_share",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#333333" },
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("%")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.upgradePosition === _vm.UPGRADE_POSITION_ENUM.common.value
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "提现银行卡", prop: "card_code" } },
                          [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入提现银行卡",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.deliveryForm.card_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.deliveryForm,
                                        "card_code",
                                        $$v
                                      )
                                    },
                                    expression: "deliveryForm.card_code",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属银行", prop: "bank_code" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "选择所属银行",
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.depositList = []
                                        _vm.deliveryForm.bank_deposit = ""
                                      },
                                    },
                                    model: {
                                      value: _vm.deliveryForm.bank_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveryForm,
                                          "bank_code",
                                          $$v
                                        )
                                      },
                                      expression: "deliveryForm.bank_code",
                                    },
                                  },
                                  _vm._l(_vm.bankList, function (item) {
                                    return _c("el-option", {
                                      key: item.bank_code,
                                      attrs: {
                                        label: item.bank_name,
                                        value: item.bank_code,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.deliveryForm.bank_name =
                                            item.bank_name
                                        },
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "开户行", prop: "bank_deposit" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      "remote-method": _vm.ajaxGetBranchList,
                                      loading: _vm.depositLoading,
                                      clearable: "",
                                      filterable: "",
                                      remote: "",
                                      placeholder: "选择开户行",
                                    },
                                    model: {
                                      value: _vm.deliveryForm.bank_deposit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveryForm,
                                          "bank_deposit",
                                          $$v
                                        )
                                      },
                                      expression: "deliveryForm.bank_deposit",
                                    },
                                  },
                                  _vm._l(_vm.depositList, function (item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.branch_name,
                                        value: item.branch_name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "持卡人", prop: "username" } },
                          [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入持卡人",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.deliveryForm.username,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.deliveryForm,
                                        "username",
                                        $$v
                                      )
                                    },
                                    expression: "deliveryForm.username",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "预留电话", prop: "bank_mobile" } },
                          [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入预留电话",
                                    maxlength: "11",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.deliveryForm.bank_mobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.deliveryForm,
                                        "bank_mobile",
                                        $$v
                                      )
                                    },
                                    expression: "deliveryForm.bank_mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "登录密码已重置",
            visible: _vm.newPassword,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.newPassword = $event
            },
            close: _vm.onHandleClosePassword,
          },
        },
        [
          _c("div", { staticClass: "contents" }, [
            _c("div", { staticClass: "mb-10" }, [
              _vm._v("登录账户：" + _vm._s(_vm.deliveryForm.account_number)),
            ]),
            _c("div", { staticClass: "mb-25 d-flex a-center" }, [
              _vm._v(" 重置密码：" + _vm._s(_vm.newPasswordText) + " "),
              _c(
                "div",
                { staticStyle: { "margin-left": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onHandleCopy },
                    },
                    [_vm._v("复制")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClosePassword } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleClosePassword },
                },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }