<template>
  <el-dialog
    title="商品补充"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <el-form
      class="contents"
      label-width="170px"
      ref="refProductForm"
      :model="productForm"
      :rules="productRules"
    >
      <el-form-item label="选择城市仓" prop="ori_id">
        <div>
          <el-select
            v-model="productForm.ori_id"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="选择品类" prop="cate_id">
        <div>
          <el-select
            v-model="productForm.cate_id"
            clearable
            filterable
            multiple
            placeholder="选择品类"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { LOGISTICS_STATUS } from "../../utils/enum";
import { getAllLogisticsList } from "@/api/member/index";
import { postAddProductTdc } from "@/api/general/city-store";
import { getAllCategoriesList } from "@/api/generalControl/category";
export default {
  name: "ProductSupplementDialog",
  props: {},
  data() {
    return {
      LOGISTICS_STATUS,
      cityStoreList: [], // 城市仓列表
      categoryList: [], //  品类列表
      dialogVisible: false,
      /** 商品补充表单数据 */
      productForm: {
        cate_id: [], // 二级品类id
        ori_id: "", // 城市仓id
      },
      submitLoading: false, // 提交按钮禁用
      /** 商品补充校验 */
      productRules: {
        ori_id: [
          {
            required: true,
            message: "请选择城市仓",
            trigger: ["blur", "change"],
          },
        ],
        cate_id: [
          {
            required: true,
            message: "请选择品类",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "data");
      this.dialogVisible = true;
      this.ajaxGetCityStoreList();
      this.getAjaxCategoriesList();
      this.productForm.tar_id = data.id;
      this.$refs.refProductForm?.resetFields();
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      const params = {
        status: LOGISTICS_STATUS.common.value,
      };
      try {
        const { data } = await getAllLogisticsList(params);
        this.cityStoreList = data;
        console.log("ajx ajaxGetCityStoreList", data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    },
    /**
     * 获取品类数据
     */
    async getAjaxCategoriesList() {
      try {
        let { data } = await getAllCategoriesList();
        this.categoryList = data;
      } catch (err) {
        console.log("ajax getAllCategoriesList err", err);
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refProductForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refProductForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        const tmpParam = cloneDeep(this.productForm);
        tmpParam.cate_id = this.productForm.cate_id.join(",");
        this.ajaxPostAddProductTdc(tmpParam);
      });
    },
    /**
     * 商品补充接口请求
     */
    async ajaxPostAddProductTdc(params) {
      try {
        await postAddProductTdc(params);
        this.$message.success("商品补充成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax postAddProductTdc err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
