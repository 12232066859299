var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "商品补充",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "refProductForm",
          staticClass: "contents",
          attrs: {
            "label-width": "170px",
            model: _vm.productForm,
            rules: _vm.productRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择城市仓", prop: "ori_id" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.productForm.ori_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.productForm, "ori_id", $$v)
                        },
                        expression: "productForm.ori_id",
                      },
                    },
                    _vm._l(_vm.cityStoreList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择品类", prop: "cate_id" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        multiple: "",
                        placeholder: "选择品类",
                      },
                      model: {
                        value: _vm.productForm.cate_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.productForm, "cate_id", $$v)
                        },
                        expression: "productForm.cate_id",
                      },
                    },
                    _vm._l(_vm.categoryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }