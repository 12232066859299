<template>
  <el-dialog
    title="升仓"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <el-form
      class="contents"
      label-width="170px"
      ref="refStorageForm"
      :model="storageForm"
      :rules="storageRules"
    >
      <el-form-item label="城市仓" prop="logistics_id">
        <div>
          <el-input
            :disabled="true"
            v-model="deliveryName"
            placeholder="请输入城市仓"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="账号昵称" prop="nickname">
        <div>
          <el-input
            v-model="storageForm.nickname"
            placeholder="请输入账号昵称"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="登录账号" prop="account_number">
        <div>
          <el-input
            v-model="storageForm.account_number"
            placeholder="请输入登录账号"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <div>
          <el-input
            type="password"
            v-model="storageForm.password"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <div>
          <el-input
            v-model="storageForm.address"
            placeholder="请输入地址"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="提现银行卡" prop="card_code">
        <div>
          <el-input
            v-model="storageForm.card_code"
            placeholder="请输入提现银行卡"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="所属银行" prop="bank_code">
        <div>
          <el-select
            @change="onChangeBank"
            v-model="storageForm.bank_code"
            clearable
            filterable
            placeholder="选择所属银行"
          >
            <el-option
              v-for="item in bankList"
              :key="item.bank_code"
              :label="item.bank_name"
              :value="item.bank_code"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="开户行" prop="bank_deposit">
        <div>
          <el-select
            :remote-method="ajaxGetBranchList"
            :loading="depositLoading"
            v-model="storageForm.bank_deposit"
            clearable
            filterable
            remote
            placeholder="选择开户行"
          >
            <el-option
              v-for="item in depositList"
              :key="item.code"
              :label="item.branch_name"
              :value="item.branch_name"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="持卡人" prop="username">
        <div>
          <el-input
            v-model="storageForm.username"
            placeholder="请输入持卡人"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="预留电话" prop="bank_mobile">
        <div>
          <el-input
            v-model="storageForm.bank_mobile"
            placeholder="请输入预留电话"
            maxlength="11"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="客服电话" prop="service_mobile">
        <div>
          <el-input
            v-model="storageForm.service_mobile"
            :maxlength="11"
            placeholder="请输入客服电话"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="身份证国徽页图片" prop="riseIdCardA">
        <el-upload
          action="#"
          v-loading="uploadLoading"
          :on-preview="onHandlePictureCardPreview"
          :class="{ hide: riseIdCardA.length || !riseIdCardA }"
          :file-list="riseIdCardA"
          list-type="picture-card"
          :http-request="(data) => onHandleUploadFile(data, 'riseIdCardA')"
          :before-remove="() => onHandleDeleteFile('riseIdCardA')"
          :limit="1"
        >
          <i slot="trigger" class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="身份证人像页图片" prop="riseIdCardB">
        <el-upload
          action="#"
          v-loading="uploadLoading"
          :on-preview="onHandlePictureCardPreview"
          :class="{ hide: riseIdCardB.length || !riseIdCardB }"
          :file-list="riseIdCardB"
          list-type="picture-card"
          :http-request="(data) => onHandleUploadFile(data, 'riseIdCardB')"
          :before-remove="() => onHandleDeleteFile('riseIdCardB')"
          :limit="1"
        >
          <i slot="trigger" class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="运距" prop="shipment_distance">
        <div>
          <el-input
            class="distance-style"
            v-model="storageForm.shipment_distance"
            @blur="
              () =>
                (storageForm.shipment_distance = utilsTool.filterUnNumber(
                  storageForm.shipment_distance
                ))
            "
            placeholder="请输入运距"
            clearable
          ></el-input>
          公里
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import utilsTool from "@/utils/tools/tool";

export default {
  name: "UpgradeStoreDialog",
  props: {
    /**
     * 银行列表
     */
    bankList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      loadImage: null,
      depositLoading: false,
      utilsTool,
      depositList: [], // 开户行列表
      /** 升仓表单数据 */
      storageForm: {
        logistics_id: "", // 城市仓id
        nickname: "", // 账号昵称
        account_number: "", // 登录账号
        password: "", // 密码
        address: "", // 地址
        card_code: "", // 提现银行卡
        bank_code: "", // 所属银行code
        bank_name: "", // 所属银行名称
        bank_deposit: "", // 开户行
        username: "", // 持卡人
        bank_mobile: "", // 预留电话
        riseIdCardA: "", // 身份证国徽页
        riseIdCardB: "", // 身份证人像页
        service_mobile: "", // 客服电话
        shipment_distance: "", // 运距
      },
      storageFormClone: {}, // 拷贝一份数据，用于初始化
      deliveryName: "", // 城市仓名称
      uploadLoading: false, // 上传loading
      submitLoading: false, // 提交按钮禁用
      riseIdCardA: [], // 身份证国徽图片
      riseIdCardB: [], // 身份证人像图片
      /** 升仓校验 */
      storageRules: {
        riseIdCardA: [
          {
            required: true,
            message: "请上传身份证国徽页图片",
            trigger: ["blur", "change"],
          },
        ],
        riseIdCardB: [
          {
            required: true,
            message: "请上传身份证人像页图片",
            trigger: ["blur", "change"],
          },
        ],
        logistics_id: [
          { required: true, message: "请输入城市仓", trigger: "blur" },
        ],
        account_number: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        card_code: [
          {
            required: true,
            message: "请输入提现银行卡",
            trigger: ["blur", "change"],
          },
        ],
        bank_code: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: ["blur", "change"],
          },
        ],
        bank_deposit: [
          {
            required: true,
            message: "请输入开户行",
            trigger: ["blur", "change"],
          },
        ],
        username: [
          {
            required: true,
            message: "请输入持卡人",
            trigger: ["blur", "change"],
          },
        ],
        bank_mobile: [
          {
            required: true,
            message: "请输入预留电话",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        /** 客服电话 */
        service_mobile: [
          {
            required: true,
            message: "请输入客服电话",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        // /** 升仓运距不必填 */
        // shipment_distance: [
        //   {
        //     required: true,
        //     message: "请输入运距",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.storageFormClone = cloneDeep(this.storageForm);
      this.depositList = [];
      this.deliveryName = data.name;
      this.storageForm.logistics_id = data.id;
      this.$refs.refStorageForm?.clearValidate();
      // 升仓 清空身份信息
      this.riseIdCardA = [];
      this.riseIdCardB = [];
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.storageForm = this.storageFormClone;
      this.$refs.refStorageForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 获得开户行列表
     */
    async ajaxGetBranchList(query) {
      this.depositList = [];
      if (!query) {
        return;
      }
      this.depositLoading = true;
      const params = {
        bank_code: this.storageForm.bank_code,
        branch_name: query,
      };
      try {
        const { data } = await this.$api.general.branchList(params);
        this.depositList = data;
      } catch (err) {
        console.log("ajax branchList err", err);
      } finally {
        this.depositLoading = false;
      }
    },
    /**
     * 修改了所属银行
     */
    onChangeBank(data) {
      this.depositList = [];
      this.storageForm.bank_deposit = "";
      const findData = this.bankList.find((item) => item.bank_code === data);
      this.storageForm.bank_name = findData?.bank_name;
      // console.log("🆒 onChangeBank", this.storageForm);
    },
    /**
     * 删除图片
     */
    onHandleDeleteFile(name) {
      this[name] = [];
      this.storageForm[name] = "";
      this.$refs.refStorageForm.validateField(name);
    },
    /**
     * 上传图片
     */
    onHandleUploadFile(options, name) {
      this.uploadLoading = true;
      // 拿到 file
      let file = options.file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // console.log(e.target.result);
        this.ajaxUploadImage(e.target.result, name, options.file.uid);
      };
    },
    /**
     * 图片路径请求
     */
    async ajaxUploadImage(img, name, uid) {
      try {
        const res = await this.$api.general.uploadImage({ file_data: img });
        // 增加uid 赋值 解决上传图片跳动问题
        this[name] = [{ url: res.data, uid }];
        this.storageForm[name] = res.data;
        this.$refs.refStorageForm.validateField(name);
      } catch (err) {
      } finally {
        this.uploadLoading = false;
      }
    },
    /**
     * 预览上传图片
     */
    onHandlePictureCardPreview(file) {
      const params = {
        bigImage: file.url,
        showImage: true,
      };
      this.$emit("on-show-image", params);
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refStorageForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const { card_code, bank_code, bank_name, bank_deposit } =
          this.storageForm;
        if (!card_code || !bank_code || !bank_name || !bank_deposit) {
          this.$message.warning("请重新选择银行信息！");
          return false;
        }
        this.submitLoading = true;
        const tmpParam = cloneDeep(this.storageForm);
        tmpParam.idcard_a = this.storageForm.riseIdCardA;
        tmpParam.idcard_b = this.storageForm.riseIdCardB;
        delete tmpParam.riseIdCardA;
        delete tmpParam.riseIdCardB;
        this.ajaxPostUpgradeLogistics(tmpParam);
      });
    },
    /**
     * 升仓接口请求
     */
    async ajaxPostUpgradeLogistics(params) {
      try {
        await this.$api.general.upgradeLogistics(params);
        this.$message.success("升仓成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax upgradeLogistics err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.distance-style {
  width: 80%;
}
</style>
