<template>
  <div class="dialog-wrap">
    <el-dialog
      title="运费结算变更记录"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClose"
      width="75%"
      center
    >
      <div
        class="row-box d-flex flex-column"
        style="height: calc(100% - 45px)"
        v-loading="dialogLoading"
      >
        <el-table
          :data="tableData"
          height="100%"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        >
          <el-table-column
            type="index"
            width="100px"
            align="center"
            label="序号"
          >
          </el-table-column>
          <el-table-column
            prop="content"
            align="center"
            label="变更记录"
            min-width="200"
          >
          </el-table-column>
          <el-table-column
            prop="business_user"
            align="center"
            label="操作人"
            min-width="100"
          >
            <template slot-scope="scope">
              {{
                (scope.row.business_user && scope.row.business_user.nickname) ||
                "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            label="操作时间"
            min-width="100"
          >
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-position">
          <el-pagination
            type="primary"
            background
            @size-change="onHandleSizeChange"
            @current-change="onHandleCurrentChange"
            :current-page="formData.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button type="primary" @click="onHandleClose">返回</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { getDeliveryChangeRecord } from "@/api/general/city-store";

export default {
  name: "DeliveryEditLogDialog",
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      /** 查询参数 */
      formData: {
        id: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      tableData: [],
      total: 0,
      tableRow: {}, // 当前行数据
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.formData.id = data.id;
      this.tableRow = data;
      this.ajaxGetDeliveryChangeRecord();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.formData = {
        ...this.formDataClone,
      };
    },
    /**
     * 获取自提点变更记录
     */
    async ajaxGetDeliveryChangeRecord() {
      this.dialogLoading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await getDeliveryChangeRecord(params);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.log("ajax getDeliveryChangeRecord err", err);
      } finally {
        this.dialogLoading = false;
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetDeliveryChangeRecord();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetDeliveryChangeRecord();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrap {
  /deep/ .el-dialog {
    height: 90% !important;
    &__body {
      height: 78%;
    }
  }
}
</style>
