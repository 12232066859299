<template>
  <el-dialog
    title="商品补充"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <el-form
      class="contents"
      label-width="170px"
      ref="refFormData"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item label="原城市仓" prop="logistics_id">
        <el-input v-model="originLogisticsName" disabled placeholder="">
        </el-input>
      </el-form-item>
      <el-form-item label="迁移到" prop="after_logistics_id">
        <div>
          <el-select
            v-model="formData.after_logistics_id"
            filterable
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :disabled="item.id === formData.logistics_id"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="迁移原因" prop="migrate_reason">
        <el-input
          type="textarea"
          v-model="formData.migrate_reason"
          :rows="3"
          placeholder="输入备注"
          maxlength="200"
          show-word-limit
          clearable
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { LOGISTICS_STATUS } from "../../utils/enum";
import { getAllLogisticsList } from "@/api/member/index";
import { postCityStoreMoveUser } from "@/api/general/city-store";

export default {
  name: "MoveUserDialog",
  props: {},
  data() {
    return {
      LOGISTICS_STATUS,
      cityStoreList: [], // 城市仓列表
      dialogVisible: false,
      formData: {
        logistics_id: "", // 原城市仓ID
        after_logistics_id: "", // 目标城市仓ID
        migrate_reason: "", // 迁移原因
      },
      formDataClone: {}, // 复制数据，用于初始化
      originLogisticsName: "", // 原城市仓名称
      submitLoading: false, // 提交按钮禁用
      formRules: {
        after_logistics_id: [
          {
            required: true,
            message: "请选择城市仓",
            trigger: ["blur", "change"],
          },
        ],
        migrate_reason: [
          {
            required: true,
            message: "请输入迁移原因",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.ajaxGetCityStoreList();
      this.formDataClone = cloneDeep(this.formData);
      this.formData.logistics_id = data.id;
      this.originLogisticsName = data.name;
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      const params = {
        status: LOGISTICS_STATUS.common.value,
      };
      try {
        const { data } = await getAllLogisticsList(params);
        this.cityStoreList = data;
        console.log("ajx getAllLogisticsList", data);
      } catch (err) {
        console.log("ajx getAllLogisticsList err", err);
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.formData = {
        ...this.formDataClone,
      };
      this.originLogisticsName = "";
      this.$refs.refFormData?.clearValidate();
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refFormData.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        const params = {
          ...this.formData,
        };
        this.ajaxPostCityStoreMoveUser(params);
      });
    },
    /**
     * 迁移用户
     */
    async ajaxPostCityStoreMoveUser(params) {
      try {
        await postCityStoreMoveUser(params);
        this.$message.success("操作成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax postCityStoreMoveUser err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
