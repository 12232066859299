/** 城市仓类型枚举 */
export const LOGISTICS_TYPE_ENUM = {
  common: {
    value: 1,
    label: "普通仓",
  },
  member: {
    value: 2,
    label: "大客户仓",
  },
};

/** 升仓标识枚举 */
export const UPGRADE_POSITION_ENUM = {
  common: {
    value: 1,
    label: "未升仓",
  },
  upgrade: {
    value: 2,
    label: "已升仓",
  },
};

/** 禁用枚举 */
export const DISABLED_ENUM = {
  disabled: {
    value: 0,
    label: "已禁用",
  },
  unDisabled: {
    value: 1,
    label: "已启用",
  },
  closed: {
    value: 2,
    label: "已关仓",
  }
};

/** 禁用数组 */
export const DISABLED_LIST = [
  {
    value: "",
    label: "全部状态",
  },
  {
    value: 0,
    label: "禁用",
  },
  {
    value: 1,
    label: "启用",
  },
  {
    value: 2,
    label: "已关仓",
  }
];

/** 提现状态 */
export const IS_WITHDRAWAL = {
  yes: {
    value: 1,
    label: "正常提现",
  },
  no: {
    value: 0,
    label: "禁止提现",
  },
};

/** 审核状态 */
export const LOGISTICS_STATUS = {
  disabled: {
    value: "0",
    label: "禁用",
  },
  common: {
    value: "1",
    label: "启用",
  },
  all: {
    value: "all",
    label: "全部",
  },
};

/** 城市仓 商品补充 对账下载 只能对admin权限 展示枚举 */
export const SHOW_ADDTDC_BUTTON_STATUS = {
  show: {
    value: "1",
    label: "展示",
  },
  notShow: {
    value: "0",
    label: "不展示",
  },
};
