import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-城市仓管理-修改提现状态
 * @param {*} params
 * @returns
 */
export function postCityStoreWithdrawalStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/withdrawalStatus`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-城市仓商品补充
 * @param {*} params
 * @returns
 */
export function postAddProductTdc(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/add/product_tdc`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-查看自提点-变更记录
 * @param {*} params
 * @returns
 */
export function getDeliveryChangeRecord(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/delivery/log`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-迁移用户
 * @param {*} params
 * @returns
 */
export function postCityStoreMoveUser(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/move/member`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-关仓
 * @param {*} params
 * @returns
 */
export function postCityStoreClose(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/close`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-查看自提点-永久关闭
 * @param {*} params
 * @returns
 */
export function postCityStoreDeliveryClose(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/delivery/close`,
    data: params,
  });
}
